/*** HERO.SCSS ***/

#hero {
	&.page {
		max-height: 325px;
		overflow: hidden;
		position: relative;
		min-height: 140px;
		width: 100%;

		img {
			width: 100%;
			display: block;
			min-height: 140px;
		}
	}

	&.home {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;

		.arrow {
			position: absolute;
			cursor: pointer;
			z-index: 2;
			top: 50%;
			margin-top: -53px;
			@include opacity(0.6);
			display: none;
			height: 106px;

			img {
				height: 100%;
			}

			&.prev {
				left: 0;
			}

			&.next {
				right: 0;
			}

			&:hover {
				@include opacity(1);
			}

			@media screen and (max-width: $phone), (max-device-width: $phone) {
				height: 70px;
				margin-top: -35px;
			}
		}

		.pager {
			position: absolute;
			text-align: center;
			bottom: $footer_height + 10;
			z-index: 2;

			li {
				display: inline-block;
				padding: 0 5px;

				a {
					display: block;
					@include border-radius(50px);
					background: rgba(#FFF,0.4);
					width: 10px;
					height: 10px;
					cursor: pointer;

					&.active {
						background: rgba(#FFF,0.8);
					}
				}
			}

			@media screen and (max-width: $phone), (max-device-width: $phone) {
				bottom: 40px;
			}
		}

		#slides {
			height: 100%;
			width: 100%;
			position: relative;
			z-index: 1;

			.slide {
				width: 100%;
				height: 100%;
				float: left;
				position: absolute;
				display: none;

				.caption-box {
					position: absolute;
					z-index: 2;
					top: 30%;

					.caption {
						right: 0;
						left: 35%;
						position: absolute;
						padding: 15px (100 - $width) / 2;
						text-align: center;
						color: #FFF;
						@include text-shadow(0 0 10px rgba(darken($blue,20),0.8));
						background: url('assets/dist/images/slider-caption-bg.png') left center repeat-y;
						z-index: 1;

						hgroup {
							h2,h3 {
								margin: 0;
								text-transform: uppercase;
							}

							h2 {
								font-size: 2.5em;

								@media screen and (max-width: $phone), (max-device-width: $phone) {
									font-size: 2.2em;
								}
							}

							h3 {
								font-size: 2em;

								@media screen and (max-width: $phone), (max-device-width: $phone) {
									font-size: 1.8em;
									margin-bottom: 15px;
								}

                                                                @media screen and (max-width: $small_phone), (max-device-width: $small_phone) {
                                                                        margin-bottom: 0;
                                                                }
							}
						}

						p {
							margin: 10px 0;
							padding: 0 15%;
							font-size: 0.9em;
							line-height: 1.4em;

							@media screen and (max-width: $small_phone), (max-device-width: $small_phone) {
								display: none;
							}
						}

						.btn {
							background: rgba(#FFF,0.35);

							&:hover {
								background: rgba(#FFF,0.45);
							}

                                                        @media screen and (max-width: $small_phone), (max-device-width: $small_phone) {
                                                                margin-top: 15px;
                                                        }
						}

						@media screen and (max-width: $tablet), (max-device-width: $tablet) {
							background-image: none;
							background: rgba($blue,0.5);
							left: 0;
						}
					}

					@media screen and (max-width: $phone), (max-device-width: $phone) {
						font-size: 0.9em;
					}
				}

				.slide-img {
					position: fixed;
					top: -50%;
					left: -50%;
					width: 200%;
					height: 200%;
					z-index: 0;

					img {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						margin: auto;
						min-width: 50%;
						min-height: 50%;
						z-index: 1;
					}
				}

				&:first-child, &.active {
					display: block;
				}
			}
		}
	}
}