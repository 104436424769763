/*** GLOBAL.SCSS ***/

html {
	height: 100%;
}

body {
	font-family: $raleway;
	height: 100%;

	* {
		@include box-sizing(border-box);
		-webkit-font-smoothing: antialiased;
	}

	hr {
		margin: 0;
		height: 5px;
		border: none;
		background: url("assets/dist/images/hr-white-bg.png") repeat-x;

		&.blue {
			background-image: url("assets/dist/images/hr-blue-bg.png");
		}
	}

	a {
		&.socicon {
			font-family: "socicon";
			text-decoration: none;
		}
	}

	.mobile-toggle-box {
		.mobile-toggle-button {
			display: none;
		}

		.mobile-toggle {
			display: block;
		}

		@media screen and (max-width: $phone), (max-device-width: $phone) {
			.mobile-toggle-button {
				display: block;
			}

			.mobile-toggle {
				display: none;
			}
		}
	}

	.zoom {
		@include transition(all 0.25s ease-in-out);

		&:hover {
			@include transform(scale(1.03));
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		line-height: 1em;
	}

	ul {
		&.plain {
			margin: 0;
			padding: 0;
			list-style: none;
		}
	}

	.btn,
	input[type="submit"] {
		text-transform: uppercase;
		color: #fff !important;
		background: $blue;
		font-weight: 700;
		padding: 12px 50px 10px;
		line-height: 1em;
		display: inline-block;
		text-decoration: none;
		border: none;

		&.small {
			font-weight: 400;
			padding: 10px 20px;
			font-size: 0.9em;
		}

		&:hover {
			text-decoration: underline;
		}

		@media screen and (max-width: $phone), (max-device-width: $phone) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.icon {
		background-position: left center;
		background-repeat: no-repeat;
		padding-left: 32px;

		&.icon-print {
			background-image: url("assets/dist/images/icon-white-printer.png");
		}

		&.icon-vcard {
			background-image: url("assets/dist/images/icon-white-vcard.png");
		}
	}

	#page-wrap {
		min-height: 100%;
		height: auto;
		position: relative;
	}

	.wrap {
		width: $width;
		position: relative;
		margin: 0 auto;
		max-width: $max_width;
	}

	.full {
		width: 100%;
	}

	.group,
	.wrap {
		zoom: 1;

		&:before,
		&:after {
			display: table;
			content: "";
		}

		&:after {
			clear: both;
		}
	}

	#breadcrumbs {
		position: absolute;
		bottom: 10px;
		background: rgba($blue, 0.4);
		color: rgba(#fff, 0.8);
		padding: 10px 0;
		text-transform: uppercase;
		font-size: 0.9em;

		a {
			text-decoration: none;
			color: #fff;
			font-weight: 700;

			&:hover {
				text-decoration: underline;
			}
		}

		@media screen and (max-width: $phone), (max-device-width: $phone) {
			display: none;
		}
	}

	.author-image img {
		border-radius: 50%;
		width: 60px;
		vertical-align: middle;
		margin-left: 10px;
	}
}
