@mixin box-sizing($val) {
	box-sizing: $val;
}

@mixin transition($val) {
	transition: $val;
}

@mixin transform($val) {
	transform: $val;
}

@mixin box-shadow($val) {
	box-shadow: $val;
}

@mixin border-radius($val) {
	border-radius: $val;
}

@mixin opacity($val) {
	opacity: $val;
}

@mixin text-shadow($val) {
	text-shadow: $val;
}
