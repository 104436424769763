/*** HEADER.SCSS ***/

header.main {
	@include box-shadow(0 0 8px rgba(#000, 0.4));
	position: absolute;
	top: 10px;
	z-index: 1;
	z-index: 1000;
	padding: 10px 0;
	background: rgba(#fff, 0.85);

	&.fixed {
		position: fixed;
		top: 0;
		background-color: #fff;
	}

	.logo {
		float: left;
		max-width: 233px;
		width: 15%;

		img {
			display: block;
			width: 100%;
		}

		@media screen and (max-width: $tablet), (max-device-width: $tablet) {
			width: 18%;
		}

		@media screen and (max-width: $phone), (max-device-width: $phone) {
			width: 155px;
		}
	}

	nav {
		float: right;
		width: 84%;
		text-align: right;
		white-space: nowrap;

		ul {
			display: inline-block;
			list-style: none;

			li {
				float: left;
				padding: 0 10px;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 0.9em;
				position: relative;

				a {
					display: block;
					color: $grey;
					text-decoration: none;
				}

				&:hover {
					.sub-menu {
						display: block;
					}
				}

				&:hover a,
				&.current-menu-item a,
				&.blue {
					color: $blue;
				}

				&.first {
					padding-left: 0;
				}

				&.last {
					padding-right: 0;
				}

				&.menu-search {
					position: relative;

					a {
						display: block;
						width: 15px;
						height: 100%;
						background: url("assets/dist/images/icon-dark-mag-glass.png") center center no-repeat;
						cursor: pointer;
					}

					form {
						display: none;
						line-height: normal;
						height: auto;
						min-width: 0;
						max-width: none;

						input {
							margin: 0;
							padding: 0;
							padding: 8px;
							border: none;
							width: 200px;
						}
					}

					&:hover {
						form {
							display: block;
						}
					}

					@media screen and (max-width: $tablet), (max-device-width: $tablet) {
						display: none;
					}
				}

				.sub-menu {
					display: none;
					margin: 0;
					position: absolute;
					background: $blue;
					@include border-radius(3px);
					padding: 10px 15px;
					text-align: center;
					top: 100%;
					max-width: 300px;
					min-width: 200px;

					li {
						color: #fff;
						float: none;
						padding: 0;
						line-height: normal !important;
						height: auto !important;
						white-space: normal;

						a {
							padding: 5px 0;
							color: #fff;

							&:hover {
								text-decoration: underline;
							}
						}

						&.divider {
							display: none;
						}

						&.spacer {
							hr {
								margin: 10px 0;
								height: 3px;
							}
						}
					}

					&:after {
						bottom: 100%;
						left: 50%;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-color: rgba(136, 183, 213, 0);
						border-bottom-color: $blue;
						border-width: 8px;
						margin-left: -8px;
					}
				}

				@media screen and (max-width: $menu_break), (max-device-width: $menu_break) {
					padding: 0 5px;
					font-size: 0.8em;
				}
			}
		}

		@media screen and (max-width: $tablet), (max-device-width: $tablet) {
			width: 80%;
		}
	}

	@media screen and (max-width: $phone), (max-device-width: $phone) {
		.mobile-toggle-box {
			float: right;
			margin-top: 7px;

			.mobile-toggle-button {
				@include border-radius(3px);
				padding: 13px 15px;
				background: $blue;
				color: #fff;
				text-transform: uppercase;
				line-height: 15px;
				cursor: pointer;

				img {
					margin-left: 5px;
				}
			}

			.mobile-toggle {
				@include border-radius(3px 0 3px 3px);
				top: 47px;
				right: 0;
				left: 0;
				width: auto;
				text-align: left;
				padding: 5px 0;
				position: absolute;
				background: $blue;
				//width: 370%;
				height: auto !important;

				ul {
					display: block;

					li {
						float: none;
						padding: 0;
						height: auto !important;
						color: #fff;
						white-space: nowrap;
						line-height: normal !important;

						a {
							color: #fff;
							padding: 10px 15px;
							display: block;

							&:hover {
								text-decoration: underline;
							}
						}

						&.divider {
							display: none;
						}

						&.menu-search,
						&.phone-number {
							display: none;
						}

						.sub-menu {
							background: darken($blue, 10);
							padding: 10px 15px;
							display: none;
							position: static;
							margin: 0 !important;
							max-width: 100%;

							li {
								white-space: normal;
								text-align: left;

								a {
									padding: 5px 0;
									font-size: 1.2em;
								}
							}

							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

nav {
	ul {
		li {
			&.divider {
				padding: 0 !important;
				min-height: 7px;
				width: 7px;
				background: url("assets/dist/images/icon-nav-divider.png") center center no-repeat;

				@media screen and (max-width: $tablet), (max-device-width: $tablet) {
					display: none;
				}
			}
		}
	}
}
