$width: 90%;
$max_width: 1200px;

$content_col: 65%;
$sidebar_col: 30%;

$menu_break: 1190px;
$tablet: 1065px;
$phone: 900px;
$small_phone: 480px;

$grey: #505149;
$blue: #25678e;

$raleway: "Raleway", sans-serif;

$footer_height: 50px;

/* Fonts */
@import url("http://fonts.googleapis.com/css?family=Raleway:400,300,500,600,700");
@font-face {
	font-family: "socicon";
	src: url("assets/dist/fonts/socicon-webfont.eot");
	src: url("assets/dist/fonts/socicon-webfont.eot?#iefix") format("embedded-opentype"), url("assets/dist/fonts/socicon-webfont.woff") format("woff"), url("assets/dist/fonts/socicon-webfont.ttf") format("truetype"), url("assets/dist/fonts/socicon-webfont.svg#sociconregular") format("svg");
	font-weight: normal;
	font-style: normal;
}
