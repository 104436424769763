/*** CONTENT.SCSS ***/

#content {
	padding: 5% 0 $footer_height * 2;

	@media screen and (max-width: $phone), (max-device-width: $phone) {
		padding: 3rem 0;
	}

	h1 {
		margin: 0;
		color: $blue;
		font-weight: 400;
		font-size: 3.8em;

		@media screen and (max-width: $phone), (max-device-width: $phone) {
			font-size: 2.4rem;
		}
	}

	a {
		color: $blue;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	.rss-feed {
		color: #ff6600;

		i {
			padding-right: 5px;
		}
	}

	#post {
		float: left;
		width: $content_col;

		@media screen and (max-width: $phone), (max-device-width: $phone) {
			float: none;
			width: 100%;
			font-size: 0.9em;
		}

		p,
		ul,
		ol,
		blockquote {
			line-height: 1.5em;
		}

		.navigation {
			margin: 40px 0 20px;

			a {
				padding: 8px 15px;
				background: lighten($grey, 35);
				color: #fff;
				text-decoration: none;
			}

			.next-posts {
				float: left;
			}

			.prev-posts {
				float: right;
			}
		}

		img {
			max-width: 100%;
			height: auto !important;
		}

		&.content {
			&--right {
				float: right;
			}
		}
	}

	#sidebar {
		float: right;
		width: $sidebar_col;

		hr {
			margin: 25px 0;
		}

		@media screen and (max-width: $phone), (max-device-width: $phone) {
			float: none;
			width: 100%;
		}
	}

	&.page {
		position: relative;

		&.contact {
			#locations {
				ul {
					float: left;
					width: 49%;

					li {
						float: left;
						margin: 0 0 3% 3%;
						width: 48.5%;

						&:nth-child(2n + 1) {
							margin-left: 0;
						}
					}

					@media screen and (max-width: $phone), (max-device-width: $phone) {
						margin-bottom: 0;
					}
				}

				#google-map {
					@include border-radius(3px);
					float: right;
					margin-bottom: 25px;
					width: 49%;
					height: 250px;
					border: 5px solid lighten($blue, 60);
				}

				ul,
				#google-map {
					@media screen and (max-width: $phone), (max-device-width: $phone) {
						float: none;
						width: 100%;
					}
				}
			}

			#staff-lists {
				column-count: 4;
				column-gap: 3%;

				@media screen and (max-width: $menu_break), (max-device-width: $menu_break) {
					column-count: 3;
				}

				@media screen and (max-width: $tablet), (max-device-width: $tablet) {
					column-count: 2;
				}

				@media screen and (max-width: $small_phone), (max-device-width: $small_phone) {
					column-count: 1;
				}

				ul {
					width: 100%;
					display: inline-block;

					li {
						position: relative;
						padding: 3px 10px;
						padding-right: 65px;

						a {
							color: #000;
							text-decoration: none;

							&:hover {
								text-decoration: underline;
							}
						}

						em {
							@include opacity(0.5);
							display: block;
							margin-bottom: 3px;
							font-size: 0.9em;
							line-height: 1em;
						}

						.icons {
							position: absolute;
							top: 3px;
							right: 5px;

							@media screen and (max-width: $phone), (max-device-width: $phone) {
								font-size: 1.3em;
							}
						}

						&.title {
							margin-top: 20px;
							padding: 0;
							font-weight: 700;

							&:hover {
								background: none;
							}
						}

						&:hover {
							background: rgba(#000, 0.1);
						}
					}
				}
			}
		}

		&--split {
			border-top: 0.5rem solid $blue;

			@media screen and (max-width: $phone), (max-device-width: $phone) {
				padding-bottom: 0;
			}

			.split {
				position: absolute;
				top: 0;
				bottom: 0;
				background-size: cover;

				@media screen and (max-width: $phone), (max-device-width: $phone) {
					position: static;
					height: 50vh;
					margin-top: 3rem;
				}

				&--right {
					right: 0;
					left: 66.666%;
				}

				&--left {
					left: 0;
					right: 66.666%;
				}
			}
		}
	}

	&.full-width {
		#post {
			width: 100%;
		}
	}

	&.landing {
		.grid {
			margin: 20px 0 0;

			ul {
				li {
					$m: 2%;
					float: left;
					margin: 0 0 $m $m;
					width: 23.5%;

					&:nth-child(4n + 1) {
						margin-left: 0;
					}

					@media screen and (max-width: $phone), (max-device-width: $phone) {
						margin: 0 0 $m $m;
						width: 32%;

						&:nth-child(4n + 1) {
							margin-left: $m;
						}

						&:nth-child(3n + 1) {
							margin-left: 0;
						}
					}

					@media screen and (max-width: $small_phone), (max-device-width: $small_phone) {
						width: 49%;

						&:nth-child(4n + 1) {
							margin-left: $m;
						}

						&:nth-child(3n + 1) {
							margin-left: $m;
						}

						&:nth-child(2n + 1) {
							margin-left: 0;
						}
					}
				}
			}
		}

		&.areas-of-practice {
			.grid {
				ul {
					li {
						@include transition(all 0.25s ease-in-out);
						padding: 20px;
						height: 106px;
						background: rgba($blue, 0.7);
						text-align: center;

						a {
							display: block;
							color: #fff;
							text-decoration: none;

							h3 {
								margin: 0;
								font-weight: 700;
								font-size: 1.4em;
								@media screen and (max-width: $phone), (max-device-width: $phone) {
									font-size: 1.2em;
								}
							}

							&:hover {
								h3 {
									text-decoration: underline;
								}
							}
						}

						&:hover {
							background: rgba($blue, 0.9);
						}
					}
				}
			}
		}

		&.professional {
			.grid {
				ul {
					li {
						a {
							position: relative;
							display: block;
							text-decoration: none;

							hgroup {
								position: absolute;
								right: 0;
								bottom: 0;
								left: 0;
								padding: 10px;
								background: rgba(#000, 0.8);
								color: #fff;

								h3 {
									margin: 0;
								}

								h4 {
									margin: 3px 0 0;
									font-weight: 300;
								}
							}

							img {
								display: block;
								width: 100%;
							}

							&:hover {
								h3 {
									text-decoration: underline;
								}
							}
						}

						&.no-image {
							a {
								min-height: 150px;
								background: #ccc;
							}
						}
					}
				}
			}
		}
	}

	&.single {
		hgroup {
			margin: 0 0 40px;

			h2 {
				font-weight: 300;
			}
		}

		#sidebar {
			hgroup {
				margin-bottom: 0;
			}
		}

		&.professional {
			padding-top: 0;

			#intro {
				position: relative;
				overflow: hidden;
				background: rgba($blue, 0.7) url("assets/dist/images/professional-bio-bg.jpg") center top no-repeat;
				background-size: cover;
				color: $blue;
				padding-top: 200px;
				padding-bottom: 50px;

				@media screen and (max-width: $phone), (max-device-width: $phone) {
					padding-top: 100px;
					padding-bottom: 0;
					background-size: auto 66%;
				}

				.column-photo {
					display: none;
					margin-bottom: 10px;
					width: 100%;

					@media screen and (max-width: $phone), (max-device-width: $phone) {
						display: block;
					}
				}

				.copy {
					position: relative;
					z-index: 2;
					float: left;
					padding: 25px 0;
					width: 60%;

					hgroup {
						white-space: nowrap;

						.job-title {
							margin: 0;
							font-weight: 300;
							font-size: 2em;
							margin-top: 10px;
						}

						@media screen and (max-width: $phone), (max-device-width: $phone) {
							white-space: normal;
							max-width: 90%;
							margin-left: auto;
							margin-right: auto;
						}
					}

					p {
						font-size: 1.5em;
						line-height: 1.7em;
					}

					a {
						color: #fff;
					}

					@media screen and (max-width: $phone), (max-device-width: $phone) {
						width: 112%;
						left: -6%;
						text-align: center;
						background-color: #fff;
					}
				}

				.cutout-photo {
					position: absolute;
					z-index: 0;
					right: 0;
					bottom: -50px;
					width: 42%;

					@media screen and (max-width: $phone), (max-device-width: $phone) {
						position: relative;
						right: initial;
						bottom: initial;
						display: block;
						margin: auto;
						width: 66%;
						max-width: 350px;
					}
				}

				@media screen and (max-width: $phone), (max-device-width: $phone) {
					font-size: 0.9em;
				}

				.links-with-icons {
					width: 75%;

					@media screen and (max-width: $phone), (max-device-width: $phone) {
						width: 90%;
						margin: auto;
					}

					li {
						display: inline-block;
						width: 48%;
						margin-bottom: 20px;
						@media screen and (max-width: $phone), (max-device-width: $phone) {
							width: 100%;
						}

						a {
							color: $blue;
							text-decoration: none;
							font-weight: 500;

							i {
								background-color: $blue;
								color: #fff;
								width: 30px;
								height: 30px;
								line-height: 30px;
								display: inline-block;
								border-radius: 50%;
								text-align: center;
								margin-right: 10px;
							}
						}
					}
				}
			}

			#overview {
				background-color: #6594b1;
				padding: 50px 0;
				position: relative;

				h2,
				p,
				a,
				li {
					color: #fff;
				}

				.overview {
					font-size: 1.3em;
					line-height: 2;
					margin-top: 0;
				}

				.col-2-3 {
					position: relative;
					padding-right: 5%;
				}

				.divider {
					content: "";
					width: 5px;
					background: url("assets/dist/images/vr-white-bg.png") repeat-y;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 35%;

					@media screen and (max-width: $phone), (max-device-width: $phone) {
						display: none;
					}
				}
			}

			#badges {
				padding: 25px 0;
				background-color: #f0f4f6;

				ul {
					li {
						display: inline-block;
						vertical-align: middle;
						padding-right: 5%;
						&:last-child {
							padding-right: 0;
						}

						img {
							display: block;
							max-height: 125px;
							margin: auto;
							max-width: 100%;
						}

						@media screen and (max-width: $phone), (max-device-width: $phone) {
							padding-right: 0;
							padding-bottom: 25px;
							text-align: center;
							width: 49%;

							&:last-child {
								padding-bottom: 0;
							}
						}
					}
				}
			}

			#details {
				padding-top: 50px;
				position: relative;

				.col-2-3 {
					position: relative;
					padding-right: 5%;
				}

				.divider {
					width: 5px;
					background: url("assets/dist/images/vr-blue-bg.png") repeat-y;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 35%;

					@media screen and (max-width: $phone), (max-device-width: $phone) {
						display: none;
					}
				}
			}
		}

		&.practice {
		}
	}

	#respond {
		border-top: 1px dashed rgba($blue, 0.4);

		textarea {
			margin-bottom: 10px;
		}
	}

	#blog-posts,
	#search-results {
		margin: 40px 0 20px;
		border-top: 1px dashed rgba($blue, 0.4);

		.blog-post,
		.search-result {
			padding: 20px 0;
			border-bottom: 1px dashed rgba($blue, 0.4);

			h2 {
				margin: 0 0 5px;
				font-weight: 400;
			}

			p {
				margin: 0;
			}

			.meta {
				font-size: 0.9em;
			}

			.entry {
				margin: 10px 0;
				overflow: auto;
				clear: both;
			}
		}
	}

	.section {
		padding: 15px 0;

		h2 {
			margin: 0 0 5px;
			color: $blue;
			font-weight: 500;
		}

		ul {
			font-size: 1.2em;
			line-height: 1.2em !important;

			li {
				margin: 10px 0;
			}
		}

		&#professionals {
			ul {
				li {
					position: relative;
					float: left;
					margin: 0 0 3% 3%;
					width: 47%;

					a {
						color: #fff;
						text-decoration: none;

						img {
							display: block;
							width: 100%;
						}

						hgroup {
							position: absolute;
							right: 0;
							bottom: 0;
							left: 0;
							display: block;
							padding: 10px;
							background: rgba(#000, 0.6);
							font-size: 0.8em;

							h3,
							h4 {
								margin: 0;
							}

							h3 {
								margin-bottom: 5px;
								font-weight: 500;
							}

							h4 {
								font-weight: 300;
							}
						}

						&:hover {
							h3 {
								text-decoration: underline;
							}
						}
					}

					&:nth-child(2n + 1) {
						margin-left: 0;
					}
				}
			}
		}
	}
}
