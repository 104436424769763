/*** FOOTER.SCSS ***/

footer.main {
	position: relative;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	margin-top: 25px;
	background: $blue;
	color: #fff;
	font-size: 0.9em;

	a {
		color: #fff;
	}

	nav {
		float: left;
		text-transform: uppercase;

		ul {
			li {
				float: left;
				padding: 0 5px;
				height: $footer_height;
				font-weight: 600;
				font-size: 0.8em;
				line-height: $footer_height;

				a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}

				&:first-child {
					padding-left: 0;
				}
			}
		}

		@media screen and (max-width: $phone), (max-device-width: $phone) {
			display: none;
		}
	}

	.social-media-sites {
		float: right;

		li {
			display: block;
			float: left;

			a {
				$icon_size: 28px;
				margin: 12px 3px 0;
				display: block;
				//padding: 6px;
				width: $icon_size;
				height: $icon_size;
				display: block;
				text-align: center;
				line-height: $icon_size;
				font-size: 1.1em;
				color: #4f84a3;
				background: #fff;
				@include border-radius(100px);

				@media screen and (max-width: $phone), (max-device-width: $phone) {
					$icon_size: 20px;
					height: $icon_size;
					width: $icon_size;
					line-height: $icon_size;
					font-size: 0.8em;
					margin: 5px 3px;
				}
			}

			@media screen and (max-width: $phone), (max-device-width: $phone) {
				display: inline-block;
				float: none;
			}
		}

		@media screen and (max-width: $phone), (max-device-width: $phone) {
			float: none;
			text-align: center;
		}
	}
}
